/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationuexcc_type_key">{{cvUexccTypeKeyLabel}}</label><label class="primary-color font-size-16">*</label>
                  <input
                    v-if = "vmuexcc_typeFormData.uexcc_type_key_old == 'new'"
                    v-model="vmuexcc_typeFormData.uexcc_type_key"
                    type="text"
                    placeholder="Please add a new Key"
                    class="form-control mb-2"
                    required
                  />

                  <!-- excTypeList -->
                  <select v-model="vmuexcc_typeFormData.uexcc_type_key_old" class="form-control" title="Select Document Type">
                    <option value="" disabled>Choose Document Type</option>
                    <option v-for="(exctype, index) of excTypeList" :key="(index+1)" :value="exctype.uexcc_type_key">
                      {{exctype.uexcc_type_key}}
                    </option>
                    <option value="new">Add New</option>
                  </select><!-- excTypeList -->
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationuexcc_org_id">{{cvUexccOrgNameLabel}}</label> <label class="primary-color font-size-16">*</label>
                  <select v-model="vmuexcc_typeFormData.uexcc_org_id" class="form-control" title="Choose Organisation">
                    <option value="" disabled>Choose Organisation / University</option>
                    <option value="">None</option>
                    <option v-for="(org, index) of univFiltersList['org_name , org_id']" :key="(index+1)" :value="org.org_id">
                      {{org.org_name}}
                    </option>
                  </select>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationuexcc_type_val">{{cvUexccTypeValLabel}}</label> <label class="primary-color font-size-16">*</label>
                  <textarea
                    v-model="vmuexcc_typeFormData.uexcc_type_val"
                    type="text"
                    class="form-control textarea"
                    required
                  ></textarea>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="validationuexcc_min_length">{{cvUexccMinLengthLabel}}</label> <label class="primary-color font-size-16">*</label>
                  <input
                    v-model="vmuexcc_typeFormData.uexcc_min_length"
                    type="text"
                    class="form-control"
                    placeholder="Ex. 160, 250"
                    required
                  />
                </div>

                <div class="col-md-6 mb-3">
                  <label for="validationuexcc_max_length">{{cvUexccMaxLengthLabel}}</label> <label class="primary-color font-size-16">*</label>
                  <input
                    v-model="vmuexcc_typeFormData.uexcc_max_length"
                    type="text"
                    class="form-control"
                    placeholder="Ex. 500, 750"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="uexcc_typeAdd()">{{ cvSubmitBtn }}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { uexccTypes } from "../../../FackApi/api/UexccType.js"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { Organisations } from "../../../FackApi/api/organisation"

export default {
  name: "uexcc_typeAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add Document Type",
      cvSubmitBtn: "Add",
      cvUexccTypeKeyLabel: "Document Type",
      cvUexccTypeValLabel: "Document Name/Description",
      cvUexccOrgNameLabel: "Organisation/University Name Requesting this Document",
      cvUexccMinLengthLabel: "Set Minimum Character Length",
      cvUexccMaxLengthLabel: "Set Maximum Character Length",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Document Added",
      vmuexcc_typeFormData: Object.assign({}, this.initFormData()),
      vmuexcc_typeDesc: null,
      vmuexcc_typeType: null,
      excTypeList: [],
      univFiltersList: []
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.univFilterList("org_name , org_id")
    this.populateExcTypesDistinct()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "uexcc_type_key": "",
        "uexcc_type_val": "",
        "uexcc_org_id": "",
        "uexcc_min_length": "",
        "uexcc_max_length": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmuexcc_typeFormData) {
          if (!this.vmuexcc_typeFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * uexcc_typeAdd
     */
    async uexcc_typeAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true

        if (this.vmuexcc_typeFormData.uexcc_type_key_old !== "new") {
          this.vmuexcc_typeFormData.uexcc_type_key = this.vmuexcc_typeFormData.uexcc_type_key_old
        }

        let uexccTypeAddResp = await uexccTypes.userextraccAdd(this, this.vmuexcc_typeFormData)
        await ApiResponse.responseMessageDisplay(this, uexccTypeAddResp)
        if (uexccTypeAddResp && !uexccTypeAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseuexcc_typeAddModal", uexccTypeAddResp.resp_data)
        }
        this.vmuexcc_typeFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at uexcc_typeAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * populateExcTypesDistinct
    */
    async populateExcTypesDistinct () {
      try {
        let excTypeResp = await uexccTypes.excTypeDistinct(this)
        if (excTypeResp && excTypeResp.resp_status) {
          this.excTypeList = excTypeResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at populateExcTypesDistinct() and Exception:", err.message)
      }
    },
    /*
    * univFilterList
    */
    async univFilterList (filterName = null) {
      if (!filterName) {
        return
      }

      try {
        let univFilterListResp = await Organisations.organisationFilterList(this, "UNIV", filterName)
        if (univFilterListResp) {
          this.univFiltersList[filterName] = univFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at univ_filter_list_resp() and Exception:", err.message)
      }
    }
  }
}
</script>
<style>
  .textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
