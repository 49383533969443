<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="uexccTypeAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100 mb-3">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchuexcc_typeWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->

              <!-- excTypeList -->
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2">
                <select v-model="uexcc_type_key" class="form-control" title="Select University" @change="setFilter">
                  <option value="" deselect>Select Document Type</option>
                  <option value="">All</option>
                  <option v-for="(exctype, index) of excTypeList" :key="(index+1)" :value="exctype.uexcc_type_key">
                    {{exctype.uexcc_type_val}}
                  </option>
                </select>
              </div><!-- excTypeList -->

              <!-- -->
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2">
                <select v-model="univ" class="form-control" title="Select University" @change="setFilter">
                  <option value="" deselect>Select University</option>
                  <option value="">All</option>
                  <option v-for="(univObj, index) of cvUniversityOptions" :key="(index+1)" :value="univObj.value">
                    {{univObj.label}}
                  </option>
                </select>
              </div>

            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="uexcc_typeObjList && uexcc_typeObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="uexcc_typeObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(uexcc_type_key)="data">
                    {{ data.item.uexcc_type_key }} <br>
                    <b-button class="actionIcon mr-1 mb-1" size="sm" @click="uexccTypeEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0 font-size-20"></i></b-button>
                    <b-button class="actionIcon" @click="showuexcc_typeDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line font-size-20 m-0"></i></b-button>
                  </template>
                  <template v-slot:cell(uexcc_type_val)="data">
                    <p class="" style="word-wrap: break-word">
                      {{ data.item.uexcc_type_val }}
                    </p>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModeluexcc_typeAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <uexccTypeAdd :propOpenedInModal="true" @emitCloseuexcc_typeAddModal="closeuexcc_typeAddModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeuexcc_typeAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModeluexcc_typeEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <uexccTypeEdit :propOpenedInModal="true" :propuexcc_typeObj="uexcc_typeEditObj" @emitCloseuexcc_typeEditModal="closeuexcc_typeEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeuexcc_typeEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModeluexcc_typeDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModeluexcc_typeDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="uexcc_typeDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { uexccTypes } from "../../../FackApi/api/UexccType.js"
import uexccTypeAdd from "./uexccTypeAdd"
import uexccTypeEdit from "./uexccTypeEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "uexcc_typeList",
  components: {
    uexccTypeAdd,
    uexccTypeEdit
  },
  data () {
    return {
      cvCardTitle: "Document Types for Students",
      cvbtnAddNew: "Add",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Document Types",
      cvAddModalHeader: "Add Document Types",
      cvbtnModalCancel: "Close",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Document Type",
      showModeluexcc_typeAdd: false,
      showModeluexcc_typeEdit: false,
      showModeluexcc_typeDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Type", key: "uexcc_type_key", class: "text-left align-text-top", sortable: true },
        { label: "Name", key: "uexcc_type_val", class: "text-left align-text-top w-350px word-wrap", sortable: true },
        { label: "Requesting Organisation", key: "uexcc_org_name", class: "text-left align-text-top", sortable: true },
        { label: "Required Minimum Chars", key: "uexcc_min_length", class: "text-left align-text-top", sortable: true },
        { label: "Allowed Maximum Chars", key: "uexcc_max_length", class: "text-left align-text-top", sortable: true }
      ],
      uexcc_typeObjList: [],
      uexcc_typeEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      whereFilter: {},
      univ: "",
      uexcc_type_key: "",
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 100,
      showLoadMoreBtn: true,
      cvUniversityOptions: [],
      excTypeList: []
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    await this.populateUniversities()
    socialvue.index()
    this.uexcc_typeList()
    this.populateExcTypesDistinct()
  },
  methods: {
    /**
     * uexcc_typeList
    */
    async setFilter () {
      this.currentBatchNo = 1
      this.uexcc_typeList()
    },

    /**
     * populateUniversities
    */
    async populateUniversities () {
      try {
        let univList = await uexccTypes.userextraccUnivList(this)
        if (!univList.resp_status) {
          ApiResponse.responseMessageDisplay(this, univList)
          return
        }
        univList = univList.resp_data.data
        this.cvUniversityOptions = univList.map((univObj) => {
          return { label: univObj.org_name, value: univObj.org_id }
        })
      }
      catch (err) {
        console.error("Exception in populateUniversities() and err: ", err.message)
      }
    },
    /**
     * populateExcTypesDistinct
    */
    async populateExcTypesDistinct () {
      try {
        let excTypeResp = await uexccTypes.excTypeDistinct(this)
        if (excTypeResp && excTypeResp.resp_status) {
          this.excTypeList = excTypeResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at populateExcTypesDistinct() and Exception:", err.message)
      }
    },
    /**
     * searchuexcc_typeWithParams
     */
    async searchuexcc_typeWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.uexcc_typeList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.uexcc_typeList(true)
    },
    /**
     * uexcc_typeList
     */
    async uexcc_typeList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.univ = this.univ
        this.whereFilter.uexcc_type_key = this.uexcc_type_key

        let uexccTypeListResp = await uexccTypes.userextraccList(this, this.whereFilter)
        if (uexccTypeListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.uexcc_typeObjList = [ ...uexccTypeListResp.resp_data.data ]
          }
          else {
            this.uexcc_typeObjList = [ ...this.uexcc_typeObjList, ...uexccTypeListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.uexcc_typeObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.uexcc_typeObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = uexccTypeListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at uexcc_typeList() and Exception:", err)
      }
    },
    /**
     * uexccTypeAdd
     */
    uexccTypeAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/uexcc_type_add")
        }
        else {
          this.showModeluexcc_typeAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at uexccTypeAdd() and Exception:", err.message)
      }
    },
    /**
     * uexccTypeEdit
     */
    uexccTypeEdit (type, item) {
      console.log("item", item)
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/uexcc_type_edit/" + this.uexcc_typeEditObj.uexcc_type_id)
        }
        else {
          this.uexcc_typeEditObj = item
          this.showModeluexcc_typeEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at uexccTypeEdit() and Exception:", err.message)
      }
    },
    /**
     * showuexcc_typeDeleteDialog
     */
    showuexcc_typeDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModeluexcc_typeDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showuexcc_typeDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * uexcc_typeDelete
     */
    async uexcc_typeDelete () {
      try {
        let uexccTypeDelResp = await uexccTypes.userextraccDelete(this, this.delObj.uexcc_type_id)
        await ApiResponse.responseMessageDisplay(this, uexccTypeDelResp)

        if (uexccTypeDelResp && !uexccTypeDelResp) {
          this.showModeluexcc_typeDelete = false
          return false
        }

        let index = this.uexcc_typeObjList.indexOf(this.delObj)
        this.uexcc_typeObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModeluexcc_typeDelete = false
      }
      catch (err) {
        console.error("Exception occurred at uexcc_typeDelete() and Exception:", err.message)
      }
    },
    /**
     * closeuexcc_typeAddModal
     */
    closeuexcc_typeAddModal (uexccTypeAddData) {
      try {
        if (uexccTypeAddData) {
          if (this.uexcc_typeObjList && this.uexcc_typeObjList.length >= 1) {
            let uexccTypeObjLength = this.uexcc_typeObjList.length
            let lastId = this.uexcc_typeObjList[uexccTypeObjLength - 1]["id"]
            uexccTypeAddData.id = lastId + 1
          }
          else {
            this.uexcc_typeObjList = []
            uexccTypeAddData.id = 11111
          }

          uexccTypeAddData.created_on = moment()
          this.uexcc_typeObjList.unshift(uexccTypeAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModeluexcc_typeAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeuexcc_typeAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeuexcc_typeEditModal
     */
    closeuexcc_typeEditModal () {
      try {
        this.showModeluexcc_typeEdit = false
        this.uexcc_typeList()
      }
      catch (err) {
        console.error("Exception occurred at closeuexcc_typeEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
